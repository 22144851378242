import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ErrorBoundary } from "@sentry/react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

import { Caption, CopySmall, CopySmallHero, H6 } from "@/system/atoms/Typography";
import { VERSION } from "@/config";

import useStyles from "../styles";

import Send from "./Send";
import { useTheme } from "@/system/theme";

export const Drawer = (props: {
	open: boolean;
	closeDrawer: () => void;
	openDrawer: () => void;
}) => {
	const { t } = useTranslation();
	const classes = useStyles({ scale: 1 });

	const theme = useTheme();

	return (
		<SwipeableDrawer
			anchor="left"
			open={props.open}
			onClose={props.closeDrawer}
			onOpen={props.openDrawer}
		>
			<Box className={classes.drawer} component="section">
				<Box display="flex" justifyContent="space-between" p={2} component="header">
					<IconButton
						color="inherit"
						size="small"
						onClick={props.closeDrawer}
						aria-label="Close side menu"
					>
						<ChevronLeft fontSize="medium" />
					</IconButton>
				</Box>

				<Box px={3} flex={1} component="main">
					<Box mb={4} component="article">
						<header>
							<CopySmallHero color={theme.palette.primary.contrastText}>
								{t("drawer.aboutUs")}
							</CopySmallHero>

							<Box my={2} />

							<H6 as="h2" color={theme.palette.primary.contrastText}>
								<Trans t={t} i18nKey="drawer.headline">
									For a <i>safer</i> togetherness
								</Trans>
							</H6>
						</header>

						<Box my={4} />

						<main>
							<CopySmall color={theme.palette.primary.contrastText}>
								{t("drawer.textLine1")}
							</CopySmall>

							<Box my={2} />

							<CopySmall color={theme.palette.primary.contrastText}>
								{t("drawer.textLine2")}
							</CopySmall>
						</main>

						<Box my={2} />

						<footer>
							<CopySmall color={theme.palette.primary.contrastText}>
								<Trans t={t} i18nKey="drawer.textLine3">
									Many thanks & <b>stay healthy!</b>
								</Trans>
							</CopySmall>

							<Box my={2} />

							<CopySmallHero color={theme.palette.primary.contrastText}>
								{t("drawer.textLine4")}
							</CopySmallHero>
						</footer>
					</Box>

					<Box mt={8} mb={2} component="article">
						<Send />
					</Box>

					<Box my={4} display="flex" flexDirection="column" component="nav">
						<Link color={theme.palette.primary.contrastText} href="https://saferspaces.io">
							<Box py={1}>
								<b>www.saferspaces.io</b>
							</Box>
						</Link>

						<Link
							color={theme.palette.primary.contrastText}
							href="https://saferspaces.io/imprint"
							target="blank"
						>
							<Box py={1}>
								<b>Impressum</b>
							</Box>
						</Link>

						<Link
							color={theme.palette.primary.contrastText}
							href="https://saferspaces.io/data-mobile"
							target="blank"
						>
							<Box py={1}>
								<b>Datenschutz</b>
							</Box>
						</Link>
					</Box>

					<Box my={2} display="flex" component="footer">
						<ErrorBoundary>
							<VersionInfo />
						</ErrorBoundary>
					</Box>
				</Box>
			</Box>
		</SwipeableDrawer>
	);
};

const VersionInfo = () => {
	const [count, setCount] = useState(0);

	return (
		<Box
			py={1}
			onClick={() => {
				setCount(() => (count > 4 ? 0 : count + 1));
			}}
		>
			<Caption color="white">Version:</Caption>
			<Caption color="white">{VERSION}</Caption>

			<Modal
				open={count === 4}
				onClose={() => {
					setCount(0);
				}}
			>
				<Box display="flex" justifyContent="center" alignItems="center" height={1} p={4}>
					<DebugInfo />
				</Box>
			</Modal>
		</Box>
	);
};

type NavigatorInfo = { key: "appCodeName" | "appName" | "appVersion"; title: string };

const DebugInfo = () => {
	const infos: NavigatorInfo[] = [
		{ key: "appCodeName", title: "AppCodeName" },
		{ key: "appName", title: "AppName" },
		{ key: "appVersion", title: "AppVersion" },
	];

	return (
		<Box p={2} bgcolor="white" borderRadius={1}>
			<Box my={1}>
				<CopySmallHero>Sprache:</CopySmallHero>
				<CopySmall>{navigator.language}</CopySmall>
			</Box>

			<Box my={1}>
				<CopySmallHero>Verfügbare Sprachen: </CopySmallHero>
				<CopySmall>{navigator.languages.join(", ")}</CopySmall>
			</Box>

			{infos.map(({ title, key }) => {
				return (
					<Box my={1} key={key}>
						<CopySmallHero>{title}:</CopySmallHero>
						<CopySmall>{navigator[key]}</CopySmall>
					</Box>
				);
			})}
		</Box>
	);
};

export default Drawer;
