export const translations = {
	back: "Zurück",
	callDirectly: "Direkt anrufen",
	situationStepSubtitle:
		"Danke für die Angabe. Gleich wirst du mit dem Team verbunden. Teile uns vorher noch kurz mit, um was für eine Art von Vorfall es sich handelt. Es handelt sich um einen ...",
	waitingMessage:
		"Bitte warte einen Moment, das Team wurde benachrichtigt und meldet sich sofort bei dir.",
	automaticUnavailableMessage:
		"Hey, danke, dass du dich meldest! Gerade ist das Team im Einsatz. Bitte bleibe hier im Chat, wir sind so schnell wie möglich für dich da.",
	waitingHeadline: "Wir sind gleich bei dir",
	waitingMessageAccepted: "Jemand vom Team macht sich jetzt auf den Weg und ist gleich bei dir.",
	waitingHeadlineAccepted: "Wir kommen zu dir",
	automaticFirstMessage:
		"Hallo, danke, dass du dich meldest. Soll sich jemand aus unserem Team auf den Weg zu dir machen oder möchtest du anonym etwas mitteilen?",
	immediateHelp: "Ich brauche Hilfe",
	error: {
		rateLimitExceeded: {
			message: "Die Anfrage wurde zu oft ausgeführt. Bitte versuche es in {{duration}} nochmal.",
			messageFallback: "Die Anfrage wurde zu oft ausgeführt. Bitte versuche es später nochmal.",
			second_one: "{{count}} Sekunde",
			second_other: "{{count}} Sekunden",
			minute_one: "{{count}} Minute",
			minute_other: "{{count}} Minuten",
			hour_one: "{{count}} Stunde",
			hour_other: "{{count}} Stunden",
		},
	},
	geoLocation: {
		modal: {
			denied: {
				headline: "So erlaubst du den Standortzugriff",
				text: "Bitte erlaube uns, deinen Standort zu verwenden. Dies ist notwendig, um herauszufinden, ob in deiner Nähe ein Team zur Unterstützung im Einsatz ist.",
				primaryButton: "Erneut versuchen",
				secondaryButton: "Schließen",
			},
			error: {
				headline: "So erlaubst du den Standortzugriff",
				text: "Bitte erlaube uns, deinen Standort zu verwenden. Dies ist notwendig, um herauszufinden, ob in deiner Nähe ein Team zur Unterstützung im Einsatz ist.",
				primaryButton: "Erneut versuchen",
				secondaryButton: "Schließen",
			},
			prompt: {
				headline: "Wir benötigen deinen Standort",
				text: "Bitte erlaube uns, deinen Standort zu verwenden. Dies ist notwendig, um herauszufinden, ob in deiner Nähe ein Team zur Unterstützung im Einsatz ist.",
				primaryButton: "Ok",
			},
			instructions: {
				forBrowser: "Für {{browser}}:",
				ios: {
					default: [
						"Wenn du z.B. {{browser}} nutzt, gehe in deine Einstellungen und erlaube dort die Nutzung deines Standorts unter den beiden folgenden Einstellungen:",
						"{{browser}} > Standort",
						"Datenschutz > Ortungsdienste > {{browser}}",
					],
				},
				android: {
					default: ["Klicke links neben der URL auf das Icon", "Berechtigungen > Standort"],
				},
			},
		},
		permission: {
			denied: {
				headline: "Wir sind gleich für dich da",
				text: "",
			},
			prompt: {
				headline: "Wir sind gleich für dich da",
				text: "",
			},
			requesting: {
				headline: "Wir sind gleich für dich da",
				text: "Dein Standort wird abgefragt. Bitte warte einen Moment.",
			},
			granted: {
				headline: "Wir sind gleich für dich da",
				text: "Bitte warte kurz, wir suchen nach einem Team in deiner Nähe.",
			},
			error: {
				headline: "Dein Standort konnte nicht ermittelt werden",
				text: "Dein aktueller Standort konnte nicht ermittelt werden. Dies kann daran liegen, dass dein Browser keine Standortabfrage unterstützt oder dass du die Abfrage abgelehnt hast. Bitte versuche es erneut oder wende dich direkt an das Personal vor Ort.",
			},
		},
		errors: {
			"1": "Dein Standort konnte nicht ermittelt werden",
			"2": "Dein aktueller Standort konnte nicht ermittelt werden. Dies kann daran liegen, dass dein Browser keine Standortabfrage unterstützt oder du die Abfrage abgelehnt hast. Bitte versuche es erneut oder wende dich direkt an das Personal vor Ort.",
			"3": "Dein Standort konnte nicht ermittelt werden. Bitte versuche es erneut oder wende dich direkt an das Personal vor Ort.",
			"4": "Dein aktueller Standort konnte nicht ermittelt werden. Dies kann daran liegen, dass dein Browser keine Standortabfrage unterstützt.",
			"5": "Dein Standort konnte nicht ermittelt werden. Bitte versuche es erneut oder wende dich direkt an das Personal vor Ort.",
		},
	},
	general: {
		ok: "Ok",
		retry: "Erneut versuchen",
		sending: "Wird abgeschickt",
	},
	role: {
		headline: "Wir sind für dich da",
		subtitle: "Bitte hilf uns noch schnell, die Situation besser einzuschätzen",
		victim: "Ich bin selbst betroffen",
		witness: "Ich habe etwas beobachtet",
	},
	situation: {
		subtitle: "Alles klar! Um welche Art von Vorfall handelt es sich?",
		discriminated: "Vorfall von Diskriminierung",
		emergency: "Medizinischen Vorfall",
		attacked: "Gewaltvollen Konflikt",
		harassed: "Sexuellen Übergriff",
		somethingWrong: "Einen anderen Vorfall",
		notify: "Einen anderen Vorfall",
		unknown: "Einen anderen Vorfall",
	},
	qrCodeInternalChat: {
		request: "Das Team wurde benachrichtigt",
		response: "Jemand ist auf dem Weg",
	},
	confirm: {
		headline: "Wir sind für dich da",
		subtitle:
			"Wenn du den Button klickst, wird unser Team über deinen aktuellen Standort informiert",
		feedback: "Feedback geben",
		button: "Ich brauche Hilfe",
	},
	drawer: {
		homepage: "Zur Startseite",
		aboutUs: "Über uns",
		headline: "Für ein <1>sichereres</1> Miteinander",
		textLine1:
			"Saferspaces schlägt eine Brücke zwischen Menschen die unsichere Situationen erleben oder beobachten und den Menschen, die vor Ort für das Wohlbefinden aller im Einsatz sind.",
		textLine2:
			"Um die Anwendung zu nutzen, muss nicht extra eine App heruntergeladen werden, da die Kontaktaufnahme über das Scannen von QR-Codes funktioniert. Die Nutzung der Anwendung erleichtert so den oft herausfordernden Schritt, aktiv auf eine Person zuzugehen und um Hilfe zu bitten, auf niedrigschwellige und zielgerichtete Weise.",
		textLine3: "Zusammen für ein respektvolles Miteinander, lieben Dank & <1>stay safe!</1>",
		textLine4: "Saferspaces",
		sendHeadline: "Wir freuen uns über <1>Feedback</1>",
		subject: "Betreff",
		message: "Nachricht",
		send: "Absenden",
	},
	home: {
		title: "Hier ist leider kein Team im Einsatz",
		description:
			"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann. Im Falle eines Notfalls, wähle die kostenlose europäische Notrufnummer 112.",
	},
	lastVisitedChat: {
		text: "Du warst anscheinend eben bereits in einer Unterhaltung. Möchtest du zu dieser zurückkehren?",
		yes: "Ja",
		no: "Nein",
	},
	venue: {
		inactiveTitle: "Hi!",
		inactiveText:
			"Saferspaces ist hier gerade nicht im Einsatz. Solltest du Hilfe benötigen, wende dich bitte direkt an das entsprechende Security oder Awareness Personal.",
		chat: {
			send: "Nachricht abschicken",
			subTitle: "Das Team hat deine Nachricht erhalten.",
		},
	},
	qrCode: {
		qrCodeAnonymous: {
			errorHeadline: "Ein <1>Fehler</1> ist aufgetreten",
			errorMessage:
				"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann.",
			errorUserFeedbackMessage:
				"Wir wurden über den Fehler informiert. Du kannst uns aber helfen, den Fehler genauer nachzuvollziehen. Beschreibe dafür, was genau du vor dem Auftreten des Fehlers gemacht hast:",
			qrCodeDynamic: {
				errorSubmit:
					"Ein Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder kontaktiere uns unter support@saferspaces.io",
				successSubmit: "Vielen Dank!",
				successTitle: "Vielen <1>Dank!</1>",
				successMessage:
					"Danke, dass du dazu beiträgst, dich für ein respektvolles Miteinander für alle einzusetzen.",
			},
		},
		qrCodeHelp: {
			handshake: {
				alt: "Symbol eines Händedrucks",
			},
		},
		qrCodeInternalChat: {
			headline: "Brauchst du <1>Support?</1>",
			hint: "Drücke den Button, um das Awareness Team zu benachrichtigen. Ort: ",
			sendSevere: "Ich brauche Hilfe",
			send: "Etwas melden",
			confirmationText: "Du befindest dich gerade hier",
			confirm: "Bestätigen",
		},
	},
	inactiveQRCode: {
		headline: "Dieser Code ist gerade nicht aktiv",
		subtitle:
			"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann. Im Falle eines Notfalls, wähle die kostenlose europäische Notrufnummer 112.",
	},
	inactive: {
		headline: "Hier ist aktuell niemand im Einsatz",
		subtitle:
			"Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann.",
		feedbackText:
			"Wenn etwas passiert ist, was du uns mitteilen möchtest nutze gerne unser Meldeformular.",
		feedbackActionText: "In Notfällen, wähle die internationale Notrufnummer 112",
		feedbackButton: "Etwas melden",
	},
	venueGeofence: {
		headline: "Für ein respektvolles Miteinander ",
		subtitle: "Wir möchten, dass sich alle Menschen wohl und sicher bei uns fühlen.",
		feedbackText:
			"Leider ist in deiner Umgebung aktuell kein Team im Einsatz. Bitte wende dich an Menschen in deiner Nähe. Wir hoffen sehr, dass dir unmittelbar geholfen werden kann.",
		feedbackButton: "Etwas melden",
		feedbackActionText: "In Notfällen, wähle die internationale Notrufnummer 112",
	},
} as const;
