import { errorLink } from "./error.link";
import { sentryLink } from "./sentry.link";
import { splitLink } from "./split.link";
import { cleanTypenameLink } from "./cleanTypename.link";

export default {
	errorLink,
	sentryLink,
	splitLink,
	cleanTypenameLink,
};
