import { createGlobalStyle } from "styled-components";
import { colorThemeRapidResponse } from "./system/color-theme.rapid-response";

const GlobalStyle = createGlobalStyle`
  :root {
		--palette-primary-light: ${colorThemeRapidResponse.palette.primary.light};
		--palette-primary-main: ${colorThemeRapidResponse.palette.primary.main};
		--palette-primary-contrastText: ${colorThemeRapidResponse.palette.primary.contrastText};

		--palette-secondary-light: ${colorThemeRapidResponse.palette.secondary.light};
		--palette-secondary-main: ${colorThemeRapidResponse.palette.secondary.main};
		--palette-secondary-dark: ${colorThemeRapidResponse.palette.secondary.dark};
		--palette-secondary-contrastText: ${colorThemeRapidResponse.palette.secondary.contrastText};

		--palette-background-default: ${colorThemeRapidResponse.palette.background.default};
		--palette-background-paper: ${colorThemeRapidResponse.palette.background.paper};

		--palette-text-primary: ${colorThemeRapidResponse.palette.text.primary};
		--palette-text-secondary: ${colorThemeRapidResponse.palette.text.secondary};

		--typography-h1-font-size: ${colorThemeRapidResponse.typography.h1.fontSize};
		--typography-h1-font-weight: ${colorThemeRapidResponse.typography.h1.fontWeight};
		--typography-h2-font-size: ${colorThemeRapidResponse.typography.h2.fontSize};
		--typography-h2-font-weight: ${colorThemeRapidResponse.typography.h2.fontWeight};
		--typography-h3-font-size: ${colorThemeRapidResponse.typography.h3.fontSize};
		--typography-h3-font-weight: ${colorThemeRapidResponse.typography.h3.fontWeight};
		--typography-h4-font-size: ${colorThemeRapidResponse.typography.h4.fontSize};
		--typography-h4-font-weight: ${colorThemeRapidResponse.typography.h4.fontWeight};
		--typography-h5-font-size: ${colorThemeRapidResponse.typography.h5.fontSize};
		--typography-h5-font-weight: ${colorThemeRapidResponse.typography.h5.fontWeight};
		--typography-h6-font-size: ${colorThemeRapidResponse.typography.h6.fontSize};
		--typography-h6-font-weight: ${colorThemeRapidResponse.typography.h6.fontWeight};
		--typography-subtitle1-font-size: ${colorThemeRapidResponse.typography.subtitle1.fontSize};
		--typography-subtitle1-font-weight: ${colorThemeRapidResponse.typography.subtitle1.fontWeight};
		--typography-subtitle2-font-size: ${colorThemeRapidResponse.typography.subtitle2.fontSize};
		--typography-subtitle2-font-weight: ${colorThemeRapidResponse.typography.subtitle2.fontWeight};
		--typography-body1-font-size: ${colorThemeRapidResponse.typography.body1.fontSize};
		--typography-body1-font-weight: ${colorThemeRapidResponse.typography.body1.fontWeight};
		--typography-body2-font-size: ${colorThemeRapidResponse.typography.body2.fontSize};
		--typography-body2-font-weight: ${colorThemeRapidResponse.typography.body2.fontWeight};
		--typography-caption-font-size: ${colorThemeRapidResponse.typography.caption.fontSize};
		--typography-caption-font-weight: ${colorThemeRapidResponse.typography.caption.fontWeight};
	}
`;

export default GlobalStyle;
