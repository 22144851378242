import { ApolloClient, ApolloLink } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { __DEV__ } from "@apollo/client/utilities/globals";

import { NAME, VERSION } from "../config";

import { cache } from "./cache";

import links from "./links";

const client = new ApolloClient({
	name: NAME,
	version: VERSION,
	link: ApolloLink.from([
		links.cleanTypenameLink,
		links.sentryLink,
		links.errorLink,
		links.splitLink,
	]),
	cache,
});

if (__DEV__) {
	// Adds messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

export default client;
