import { PropsWithChildren, useState } from "react";

import { useTranslation } from "react-i18next";

import SaferSpacesLogo from "@/components/atoms/SaferSpacesLogo";
import Drawer from "@/components/environments/DefaultLayout/components/Drawer";
import useStyles from "@/components/environments/DefaultLayout/styles";
import IconsButtons from "@/components/atoms/IconButtons/IconButtons";
import { useScrollListener } from "@/hooks/useScrollListener";
import { Footer } from "@/system/atoms/Components";
import { LanguageToggle } from "@/system/atoms/LanguageToggle/LanguageToggle";

const DefaultLayout = (props: PropsWithChildren<{ noMargin?: boolean }>) => {
	const { i18n } = useTranslation();
	const [scale, setScale] = useState(1);
	const [open, setOpen] = useState(false);
	const classes = useStyles({ scale, noMargin: props.noMargin });

	const scrollRef = useScrollListener((current) => () => {
		const scrollTop = current.scrollTop;

		setScale(Math.max((80 - scrollTop) / 80, 0));
	});

	return (
		<section className={classes.background}>
			<header className={classes.header}>
				<IconsButtons.Menu
					onBackground
					onClick={() => {
						setOpen(true);
					}}
				/>
				<Drawer
					open={open}
					openDrawer={() => {
						setOpen(true);
					}}
					closeDrawer={() => {
						setOpen(false);
					}}
				/>
				<figure style={{ paddingTop: 12 }}>
					<SaferSpacesLogo height={64} width={64} variant="top-right" />
				</figure>
			</header>

			<main className={classes.main} ref={scrollRef}>
				<article className={classes["main--content"]}>{props.children}</article>

				<Footer display="flex" justifyContent="center" mt={2} mb={1}>
					<LanguageToggle
						language={i18n.language === "de" ? "de" : "en"}
						onClick={() => {
							i18n.changeLanguage(i18n.language === "de" ? "en" : "de");
						}}
					/>
				</Footer>
			</main>
		</section>
	);
};

export default DefaultLayout;
