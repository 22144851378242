import { lighten, createTheme, ThemeProvider, darken } from "@mui/material";

import { designTokens } from "./theme.tokens";

import { colorThemeRapidResponse } from "./color-theme.rapid-response";

export { type Theme, useTheme } from "@mui/material/styles";
export { makeStyles, styled } from "@mui/styles";

declare module "@mui/material/styles" {
	interface Palette {
		tertiary: Palette["primary"];
	}

	interface PaletteOptions {
		tertiary: PaletteOptions["primary"];
	}
}

export const finalTheme = (theme = colorThemeRapidResponse) => {
	return createTheme(theme, {
		components: {
			MuiAlert: {
				styleOverrides: {
					message: theme.typography.subtitle2,
					root: {
						display: "flex",
						alignItems: "center",
					},
				},
			},
			MuiRadio: {
				styleOverrides: {
					colorPrimary: {
						"&:active": {
							color: designTokens.colors.green.mid,
						},
					},
				},
			},
			MuiFormControl: {
				styleOverrides: {
					root: {
						background: darken(theme.palette.background.default, 0.025),
					},
				},
			},
			// TODO: Check if both are necessary
			MuiInput: {
				styleOverrides: {
					root: {
						...theme.typography.subtitle2,
					},
				},
			},
			// TODO: Check if both are necessary
			MuiInputBase: {
				styleOverrides: {
					root: {
						...theme.typography.subtitle2,
					},
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: theme.typography.subtitle1,
				},
			},
			MuiButtonBase: {
				defaultProps: {
					disableRipple: true,
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						"&:focus": {
							border: `solid 1px ${theme.palette.primary.main}`,
							background: theme.palette.secondary.light,
							boxShadow: `inset 0px 0px 0px 2px white`,
							">svg": {
								stroke: "white",
							},
						},
					},
					colorInherit: {
						"&:focus": {
							border: `solid 1px ${theme.palette.primary.main}`,
							background: theme.palette.secondary.light,
							boxShadow: `inset 0px 0px 0px 2px white`,
							">svg": {
								stroke: "white",
							},
						},
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						fontSize: 18,
					},
					textSizeLarge: {
						borderRadius: designTokens.button.sizes.large.height / 2,
						height: designTokens.button.sizes.large.height,
					},
					textSizeMedium: {
						borderRadius: designTokens.button.sizes.medium.height / 2,
						height: designTokens.button.sizes.medium.height,
					},
					textSizeSmall: {
						borderRadius: designTokens.button.sizes.small.height / 2,
						height: designTokens.button.sizes.small.height,
					},
					text: {
						padding: "0 24px",

						"&:focus": {
							border: `solid 1px ${theme.palette.secondary.main}`,
							background: theme.palette.secondary.light,
							boxShadow: `inset 0px 0px 0px 2px white`,
							color: "white",
						},
					},
					containedSizeLarge: {
						borderRadius: designTokens.button.sizes.large.height / 2,
						height: designTokens.button.sizes.large.height,
					},
					containedSizeMedium: {
						borderRadius: designTokens.button.sizes.medium.height / 2,
						height: designTokens.button.sizes.medium.height,
					},
					containedSizeSmall: {
						borderRadius: designTokens.button.sizes.small.height / 2,
						height: designTokens.button.sizes.small.height,
					},
					containedSecondary: {
						transition: "all 0.25s linear",

						"&:focus": {
							border: `solid 1px ${theme.palette.secondary.main}`,
							background: theme.palette.secondary.light,
							boxShadow: `inset 0px 0px 0px 2px white`,
						},

						"&:hover": {
							background: theme.palette.secondary.light,
							border: `none`,
							boxShadow: "none",
						},

						"&:active": {
							background: theme.palette.secondary.dark,
							border: `none`,
							boxShadow: "none",
						},
					},
					outlinedSizeLarge: {
						borderRadius: designTokens.button.sizes.large.height / 2,
						height: designTokens.button.sizes.large.height,
					},
					outlinedSizeMedium: {
						borderRadius: designTokens.button.sizes.medium.height / 2,
						height: designTokens.button.sizes.medium.height,
					},
					outlinedSizeSmall: {
						borderRadius: designTokens.button.sizes.small.height / 2,
						height: designTokens.button.sizes.small.height,
					},
					outlinedPrimary: {
						transition: "all 0.25s linear",
						border: `solid 2px ${theme.palette.primary.main}`,

						"&:focus": {
							border: `solid 1px ${theme.palette.primary.main}`,
							background: theme.palette.secondary.light,
							boxShadow: `inset 0px 0px 0px 2px white`,
							color: "white",
						},

						"&:hover": {
							border: `solid 2px ${theme.palette.primary.light}`,
							background: "transparent",
							boxShadow: "none",
							color: `${theme.palette.primary.light}`,
						},

						"&:active": {
							border: `solid 2px ${theme.palette.primary.dark}`,
							background: "transparent",
							boxShadow: "none",
							color: `${theme.palette.primary.dark}`,
						},
					},
				},
			},
		},
	});
};

export { ThemeProvider, createTheme, lighten };
